import { format } from 'date-fns';
import { subscribe, BUS_EVENT_TYPES, publish } from './eventBus.js';
import { isAdmin } from './userRole.js';

subscribe(BUS_EVENT_TYPES.loading, () => {
  const table = document.getElementById('data');
  table.innerHTML = '';
});

/**
 *
 * @param {*} _event - Event
 * @param {*} rowIndex - Row index
 */
function tableMouseOverHandler(_event, rowIndex) {
  publish(BUS_EVENT_TYPES.tableMouseOver, {
    rowIndex,
  });
}

/**
 *
 * @param {*} _event - Event
 * @param {*} rowIndex - Row index
 */
function tableMouseOutHandler(_event, rowIndex) {
  publish(BUS_EVENT_TYPES.tableMouseOut, { rowIndex });
}

// Add heading into data
const columns = [
  { title: 'Event Type', property: 'eventType' },
  { title: 'Event Time (hh:mm:ss 24hr)', property: 'eventTime' },
  { title: 'Latitude', property: 'latitude' },
  { title: 'Longitude', property: 'longitude' },
];

/**
 *
 * @param {[]} avlEvents - AVL events
 * @param {*} features - Features
 */
export default function addTable(avlEvents, features) {
  const table = document.getElementById('data');
  table.innerHTML = '';

  let tableRow = table.insertRow();
  columns.forEach((heading) => {
    const th = document.createElement('th');
    const text = document.createTextNode(heading.title);
    th.appendChild(text);
    tableRow.appendChild(th);
  });

  avlEvents.forEach((avlEvent, rowIndex) => {
    tableRow = table.insertRow();

    columns.forEach((column, columnindex) => {
      const cell = tableRow.insertCell();

      // Use human readable format for date
      const cellData = avlEvent[column.property];
      // show date in local time in 24 hour format
      const formattedData = columnindex === 1 ? format(new Date(cellData), 'HH:mm:ss') : cellData;
      cell.innerHTML = formattedData;
    });

    tableRow.addEventListener('mouseover', (event) => tableMouseOverHandler(event, rowIndex));
    tableRow.addEventListener('mouseout', (event) => tableMouseOutHandler(event, rowIndex, features));
  });
}
