import { formatDuration } from 'date-fns';
import { subscribe, BUS_EVENT_TYPES } from './eventBus.js';
import deviceList from '../helpers/deviceList.js';
import { regenTotalDuration } from './fuelRegenCalc.js';
import AvlEvent from './avlevent.js';

/**
 * @typedef {{[key: string]: number|string}} SummaryItems
 * @param {AvlEvent[]} events - Events
 * @returns {SummaryItems} - Event type counts
 */
function eventTypeCounter(events) {
  const eventTypeCounts = {
    'Ignition On': 0,
    'Ignition Off': 0,
    'Fuel On': 0,
    'ReGen On': 0,
    'ReGen Off': 0,
  };

  events.forEach((event) => {
    const { eventType } = event;
    if (eventTypeCounts[eventType]) {
      eventTypeCounts[eventType] += 1;
    } else {
      eventTypeCounts[eventType] = 1;
    }
  });

  return eventTypeCounts;
}

/**
 *
 * @param {AvlEvent[]} events - Events
 * @param {number} fuelMultiplier - Fuel multiplier
 * @returns {{[key: string]: number}} - Event type counts
 */
function calculateEventTypeCounts(events, fuelMultiplier) {
  const summaryData = eventTypeCounter(events);
  // handle error thrown by regenTotalDuration
  let regenDurationFormatted = 'N/A';
  try {
    const regenDuration = regenTotalDuration(events);

    const duration = {
      hours: Math.floor(regenDuration / 3600),
      minutes: Math.floor((regenDuration % 3600) / 60),
    };
    regenDurationFormatted = formatDuration(duration);
  } catch (error) {
    console.error(error);
  }

  try {
    summaryData['Fuel Used'] = `${summaryData['Fuel On'] * fuelMultiplier} gals`;
    summaryData['Regen Duration'] = regenDurationFormatted;
    delete summaryData['Fuel On'];
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
  }

  return summaryData;
}

/**
 *
 * @param {[]} summaryData - Summary data
 * @returns {string} - Summary HTML
 */
function generateSummaryHtml(summaryData) {
  let response = '<h4>Summary</h4>';
  Object.entries(summaryData).forEach(([eventType, count]) => {
    response += `
      <dl class="row mb-0">
        <dt class="col">${eventType}</dt>
        <dd class="col">${count}</dd>
      </dl>`;
  });
  return response;
}

/**
 *
 * @param {AvlEvent[]} data - event data
 * @returns {string} - Summary HTML
 */
function eventsSummary(data) {
  try {
    const { deviceId } = data[0];
    const { fuelMultiplier } = deviceList[deviceId];
    const summaryData = calculateEventTypeCounts(data, fuelMultiplier);
    return generateSummaryHtml(summaryData);
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    return '';
  }
}

subscribe(BUS_EVENT_TYPES.loading, () => {
  document.querySelector('#event-summary').hidden = true;
});

/**
 *
 * @param {[]} data - Event data
 */
export default function renderEventSummary(data) {
  const elementSummary = document.getElementById('event-summary');
  elementSummary.innerHTML = eventsSummary(data);
  elementSummary.hidden = false;
}
