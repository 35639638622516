import { format } from 'date-fns';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import { getRelativePosition } from 'chart.js/helpers';
import { throttle } from 'lodash';
import annotationPlugin from 'chartjs-plugin-annotation';
import featureFlags from '../config.js';
import { subscribe, BUS_EVENT_TYPES, publish } from './eventBus.js';
import { setTimeToBeforeMidnight, setTimeToMidnight } from './dateUtils.js';
import getChartContext, { getInputElement } from '../helpers/chartHelpers.js';
import { getDeviceDetails, getFuelRegen } from './apiService.js';
import { calculateAverageRegenDuration } from './fuelRegenCalc.js';

let timelineChart;
Chart.register(annotationPlugin);

const timeFormat = 'HH:mm';
const throttledPublish = throttle(publish, 500);

/**
 * @typedef {import ('../../types/models').AvlEvent} AvlEvent
 */

/**
 *
 */
function clearChart() {
  if (timelineChart) { timelineChart.destroy(); }
}

subscribe(BUS_EVENT_TYPES.loading, clearChart);

/**
 *
 * @param {Date} startTime - start time
 * @param {Date} endTime - end time
 */
function updateStartEndTimes(startTime, endTime) {
  const startTimeElement = getInputElement('startTime');
  startTimeElement.value = format(new Date(startTime), timeFormat);
  const endTimeElement = getInputElement('endTime');
  endTimeElement.value = format(new Date(endTime), timeFormat);
  endTimeElement.dispatchEvent(new Event('change'));
}

/**
 *
 * @param {AvlEvent[]} events - events
 * @returns {[]} - fuel events
 */
function extractFuelEvents(events) {
  const fuelEvents = [];
  // @type {AvlEvent | null} currentRegenOn
  let currentRegenOn = null;
  // @type {AvlEvent | null} currentIgnitionOn
  let currentIgnitionOn = null;

  events.reverse().forEach((event) => {
    if (event.eventType === 'ReGen On' && !currentRegenOn) {
      currentRegenOn = event;
    } else if (event.eventType === 'ReGen Off' && currentRegenOn) {
      fuelEvents.push({
        label: 'Regen On',
        data: [
          null,
          [new Date(currentRegenOn.eventTime), new Date(event.eventTime)],
        ],
        backgroundColor: 'green',
      });
      currentRegenOn = null;
    } else if (event.eventType === 'Ignition On' && !currentIgnitionOn) {
      currentIgnitionOn = event;
    } else if (event.eventType === 'Ignition Off') {
      const startTime = currentIgnitionOn
        ? currentIgnitionOn.eventTime : events[0].eventTime;
      fuelEvents.push({
        label: 'Ignition On',
        data: [
          [new Date(startTime), new Date(event.eventTime)],
          null,
        ],
        backgroundColor: 'Blue',
      });
      currentIgnitionOn = null;
    }
  });

  if (currentIgnitionOn) {
    fuelEvents.push({
      label: 'Ignition On',
      data: [
        [new Date(currentIgnitionOn.eventTime),
          new Date(events[events.length - 1].eventTime)],
        null,
      ],
      backgroundColor: 'Blue',
    });
  }

  return fuelEvents;
}

/**
 *
 * @param {Event} event - event
 */
function chartHoverHandler(event) {
  const canvasPosition = getRelativePosition(event, timelineChart);

  // Substitute the appropriate scale IDs
  const dataX = timelineChart.scales.x.getValueForPixel(canvasPosition.x);
  publish(BUS_EVENT_TYPES.eventChartHover, { hoverTimeStamp: dataX });
}

const throttledChartHoverHandler = throttle(chartHoverHandler, 600);

/**
 *
 * @param {[]} fuelEvents - fuel events
 * @returns {{chartMinDate: Date, chartMaxDate: Date}} - chart date range
 */
function getChartDateRange(fuelEvents) {
  if (fuelEvents.length < 1) {
    throw new Error('No fuel events found');
  }
  const chartMinDate = fuelEvents[0].data[1] ? fuelEvents[0].data[1][0] : fuelEvents[0].data[0][0];
  const chartMaxDate = fuelEvents[fuelEvents.length - 1].data[0]
    ? fuelEvents[fuelEvents.length - 1].data[0][1] : fuelEvents[fuelEvents.length - 1].data[1][1];

  return { chartMinDate, chartMaxDate };
}

/**
 *
 * @param {[]} fuelEvents - fuel events
 * @returns {{}} - annotation config
 */
function getAnnotationConfig(fuelEvents) {
  // loop through the regen hours and add annotations to the chart for each element in the array
  const baseAnnotationConfig = {
    type: 'line',
    xMin: null,
    xMax: null,
    borderColor: '#bde0fe',
    borderWidth: 2,
    label: {
      display: true,
      content: '',
      position: 'start',
    },
  };

  const annotationConfig = {};
  fuelEvents.forEach((regen, index) => {
    const formattedFuelUsed = Number((regen.fuel_used).toFixed(1));

    annotationConfig[`line${index + 1}`] = {
      ...baseAnnotationConfig,
      xMin: new Date(regen.regen_hour_end),
      xMax: new Date(regen.regen_hour_end),
      label: {
        content: `${formattedFuelUsed} gals`,
        display: true,
        position: 'start',
      },
    };
  });

  return annotationConfig;
}

/**
 * Creates a timeline chart using Chart.js.
 * @param {CanvasRenderingContext2D} ctx - The 2D rendering context for the drawing
 *  surface of a <canvas> element.
 * @param {Array} fuelEvents - An array of fuel events to be displayed on the chart.
 * @param {Date} chartMinDate - The minimum date to be displayed on the chart.
 * @param {Date} chartMaxDate - The maximum date to be displayed on the chart.
 * @param {object} annotationConfig - Configuration object for the chart annotations.
 */
function createTimelineChart(ctx, fuelEvents, chartMinDate, chartMaxDate, annotationConfig) {
  // clear the existing chart if any
  clearChart();

  // create the new chart
  timelineChart = new Chart(ctx, {
    type: 'bar',
    data: {
      labels: [
        'Ignition',
        'Regen',
      ],
      datasets: fuelEvents,
    },
    options: {
      events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
      onHover: throttledChartHoverHandler,
      responsive: false,
      indexAxis: 'y',

      plugins: {
        annotation: { annotations: annotationConfig },
        crosshair: {
          sync: {
            enabled: true,
          },
        },
        legend: {
          display: false,
        },
        title: {
          display: false,
          // text: 'Chart.js Floating Horizontal Bar Chart'
        },
        tooltip: {
          callbacks: {
            label(context) {
              if (context.dataset.data) {
                const selectedItem = context.dataset.data[0]
                  ? context.dataset.data[0] : context.dataset.data[1];
                const startTime = selectedItem[0];
                const endTime = selectedItem[1];
                if (featureFlags.updateTableonChartHover) updateStartEndTimes(startTime, endTime);
                return `${format(new Date(startTime), timeFormat)} - ${format(new Date(endTime), timeFormat)}`;
              }
              return '';
            },
          },
        },
      },
      scales: {
        y: {
          stacked: true,
        },
        x: {
          type: 'time',
          adapters: {
            date: {
              zone: 'America/Los_Angeles',
            },
          },
          time: {
            tooltipFormat: 'MM/DD/YYYY',
            displayFormats: {
              hour: 'HH',
            },
          },
          min: setTimeToMidnight(chartMinDate),
          max: setTimeToBeforeMidnight(chartMaxDate),
        },
      },
    },
  });

  timelineChart.canvas.addEventListener('mouseout', () => {
    throttledPublish(BUS_EVENT_TYPES.eventChartHoverOut);
  });
}

/**
 *
 * @param {import ('../../types/models').RegenHourFuel[]} regenHourFuel - regen hour fuel
 * @param {Date} selectedDate - selected date
 * @param {string} selectedDeviceId - selected device id
 * @returns {Promise<void>} - Promise
 */
async function renderRegenTwoWeekAverage(regenHourFuel, selectedDate, selectedDeviceId) {
  try {
    const averageFuelEvents = calculateAverageRegenDuration(selectedDate, regenHourFuel);
    const deviceDetails = await getDeviceDetails();
    const { fuelMultiplier } = deviceDetails.filter(
      (device) => device.calampDeviceId === selectedDeviceId,
    )[0];
    const averageFuelUsed = Math.round(averageFuelEvents * fuelMultiplier);
    // delete the existing element if any
    const existingElement = document.getElementById('2wk-regen-fuel');
    if (existingElement) {
      existingElement.remove();
    }
    const element = document.getElementById('event-summary');
    const html = `
    <dl id="2wk-regen-fuel" class="row mb-0">
      <dt class="col">2 week Avg. Regen Fuel Usage </dt>
      <dd class="col">${averageFuelUsed} gals</dd>
    </dl>`;
    element.innerHTML += html;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Renders a timeline chart with fuel events and regen hours for a selected device and date.
 * @param {object} eventContext - The context of the event that triggered the function.
 * @returns {Promise<void>} A Promise that resolves when the chart has been created.
 */
async function renderTimeLineChart(eventContext) {
  const { avlEvents, selectedDeviceId, selectedDate } = eventContext.detail;
  const fuelEvents = extractFuelEvents(avlEvents);

  // Don't do anything if data is empty.
  // Should handle this in the calling function
  if (fuelEvents.length < 1) return;
  const ctx = getChartContext('chart');
  const { chartMinDate, chartMaxDate } = getChartDateRange(fuelEvents);
  const regenHours = await getFuelRegen(selectedDeviceId);
  renderRegenTwoWeekAverage(regenHours, selectedDate, selectedDeviceId);
  const selectedDateStart = new Date(`${selectedDate}T00:00:00`);
  const selectedDateEnd = new Date(`${selectedDate}T23:59:59`);

  // filter regen hours to only include those that are within the selected date
  const regenHoursForSelectedDate = regenHours.filter((regen) => {
    const regenEnd = new Date(regen.regen_hour_end);
    return regenEnd >= selectedDateStart && regenEnd <= selectedDateEnd;
  });

  const annotationConfig = getAnnotationConfig(regenHoursForSelectedDate);
  createTimelineChart(ctx, fuelEvents, chartMinDate, chartMaxDate, annotationConfig);
}

subscribe(BUS_EVENT_TYPES.dataLoaded, renderTimeLineChart);

export default renderTimeLineChart;

export {
  clearChart,
  createTimelineChart,
  getAnnotationConfig,
  getChartDateRange,
};
