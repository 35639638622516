/**
 *
 * @param {Date} date - The date to set the time to midnight
 * @returns {Date} - The date with the time set to midnight
 */
function setTimeToMidnight(date) {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
}

/**
 *
 * @param {Date} date - The date to set the time to before midnight
 * @returns {Date} - The date with the time set to before midnight
 */
function setTimeToBeforeMidnight(date) {
  const newDate = new Date(date);
  newDate.setHours(23, 59, 59, 999);
  return newDate;
}

export { setTimeToBeforeMidnight, setTimeToMidnight };
