/**
 * Get the canvas rendering context
 * @param {string} elementId - The id of the canvas element
 * @returns {CanvasRenderingContext2D} getContext - The canvas rendering context
 */
export default function getChartContext(elementId) {
  const chartElement = document.getElementById(elementId);
  if (chartElement instanceof HTMLCanvasElement) {
    return chartElement.getContext('2d');
  }
  throw new Error('The element is not a canvas element');
}

/**
 *
 * @param {string} elementId - The id of the input element
 * @returns {HTMLInputElement} element - The input element
 */
export function getInputElement(elementId) {
  const element = document.getElementById(elementId);
  if (element instanceof HTMLInputElement) {
    return element;
  }
  throw new Error('The element is not an input element');
}
