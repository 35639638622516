import Map from 'ol/Map';
import View from 'ol/View';
import Overlay from 'ol/Overlay';
import GeoJSON from 'ol/format/GeoJSON';
import { Vector as VectorLayer } from 'ol/layer';
import {
  Circle, Fill, Stroke, Style,
} from 'ol/style.js';
import { Vector as VectorSource } from 'ol/source';
import { transform, fromLonLat } from 'ol/proj';
import { apply } from 'ol-mapbox-style';
import LayerGroup from 'ol/layer/Group.js';
import { subscribe, BUS_EVENT_TYPES } from './eventBus.js';
import 'ol/ol.css';
import AvlEvent from './avlevent.js';

let eventsGeoJson = {
  type: 'FeatureCollection',
  features: [],
};

let map;
let markerLayer;
let popover;
// eslint-disable-next-line import/no-mutable-exports
let features = [];

/**
 *
 * @param {AvlEvent[]} eventData - event eventData
 * @returns {object} - GeoJSON object
 */
function setGeoJSONFeatures(eventData) {
  eventsGeoJson.features = [];
  eventData.forEach((avlEvent) => {
    eventsGeoJson.features.push({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: transform([avlEvent.longitude, avlEvent.latitude], 'EPSG:4326', 'EPSG:3857'),
      },
      properties: {
        name: avlEvent.eventType,
        event_time: avlEvent.eventTime,
      },
    });
  });

  return eventsGeoJson;
}

/**
 *
 * @param {string} style - style object
 * @returns {Style} - OpenLayers Style object
 */
function getMarkerStyle(style) {
  const makerStyles = {
    default: [0, 0, 0, 0],
    hover: '#FF0000',
  };

  return new Style({
    image: new Circle({
      radius: 10,
      fill: new Fill({
        color: makerStyles[style],
      }),
      stroke: new Stroke({
        color: [213, 213, 213, 0.2],
      }),
    }),
  });
}

/**
 *
 */
function clearMarkers() {
  if (markerLayer) {
    map.removeLayer(markerLayer);
    markerLayer = undefined;
  }
}

subscribe(BUS_EVENT_TYPES.loading, clearMarkers);

/**
 *
 */
function disposePopover() {
  if (popover) {
    popover.dispose();
    popover = undefined;
  }
}

/**
 *
 */
function setupPopup() {
  const element = document.getElementById('popup');
  const popup = new Overlay({
    element,
    positioning: 'bottom-center',
    stopEvent: false,
  });
  map.addOverlay(popup);

  // display popup on click
  map.on('click', (evt) => {
    const feature = map.forEachFeatureAtPixel(evt.pixel, (feat) => feat);
    disposePopover();
    if (!feature) {
      return;
    }
    popup.setPosition(evt.coordinate);

    // @ts-expect-error Need to import boostrap via node modules to fix this
    popover = new window.bootstrap.Popover(element, {
      placement: 'top',
      html: true,
      content: feature.get('name'),
    });
    popover.show();
  });
}

/**
 *
 * @param {AvlEvent[]} avlEvents - AVL events collection
 */
function renderGeoJson(avlEvents) {
  eventsGeoJson = setGeoJSONFeatures(avlEvents);
  disposePopover();
  // Create an OpenLayers source from the GeoJSON
  features = new GeoJSON().readFeatures(eventsGeoJson);
  const source = new VectorSource({
    features,
  });

  // Create an OpenLayers layer to render the points
  markerLayer = new VectorLayer({
    source,
    style: getMarkerStyle('default'),
  });

  map.addLayer(markerLayer);

  if (avlEvents[0]) {
    map.getView().animate({
      center: fromLonLat([avlEvents[0].longitude, avlEvents[0].latitude]),
    });
  }
}

/**
 * Function to initialize the map
 * @returns {Promise<Map|LayerGroup>} - OpenLayers Map object
 */
function initMap() {
  const apiKey = window.mapApiKey;
  map = new Map({ target: 'map' });
  map.setView(
    new View({
      center: fromLonLat([-94.457892, 42.7567896]),
      zoom: 17,
    }),
  );
  const basemapId = 'ArcGIS:Imagery:Standard';
  const basemapURL = `https://basemaps-api.arcgis.com/arcgis/rest/services/styles/${basemapId}/?type=style&token=${apiKey}`;
  setupPopup();
  return apply(map, basemapURL);
}

// set all markers to default style
subscribe(BUS_EVENT_TYPES.eventChartHoverOut, () => {
  features.forEach((feature) => {
    feature.setStyle(getMarkerStyle('default'));
  });
});

/**
 *  loop through features and find the one that matches
 * the eventDetail.hoverTimeStamp +/- 1 minute
 * set the style to hover
 */
subscribe(BUS_EVENT_TYPES.eventChartHover, (eventDetail) => {
  const { hoverTimeStamp } = eventDetail.detail;
  const hoverTime = new Date(hoverTimeStamp);
  const oneMinute = 60000;
  const oneMinuteAgo = new Date(hoverTime.getTime() - oneMinute);
  const oneMinuteFromNow = new Date(hoverTime.getTime() + oneMinute);
  features.forEach((feature) => {
    const eventTime = new Date(feature.get('event_time'));
    if (eventTime >= oneMinuteAgo && eventTime <= oneMinuteFromNow) {
      feature.setStyle(getMarkerStyle('hover'));
    } else {
      feature.setStyle(getMarkerStyle('default'));
    }
  });
});

subscribe(BUS_EVENT_TYPES.tableMouseOver, (eventDetail) => {
  const { rowIndex } = eventDetail.detail;
  const marker = features[rowIndex];
  if (marker) {
    marker.setStyle(getMarkerStyle('hover'));
  }
});

subscribe(BUS_EVENT_TYPES.tableMouseOut, (eventDetail) => {
  const { rowIndex } = eventDetail.detail;
  const marker = features[rowIndex];
  if (marker) {
    marker.setStyle(getMarkerStyle('default'));
  }
});

document.addEventListener('DOMContentLoaded', initMap);

export {
  getMarkerStyle,
  renderGeoJson, features, clearMarkers,
};
