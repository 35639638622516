<template>
  <BreadCrumbComponent />
</template>

<script>
// Import your components here
// For example: import DeviceSelector from './components/DeviceSelector.vue';

import BreadCrumbComponent from './components/BreadCrumbComponent.vue';

export default {
  name: 'AppTemp',
  components: {
    // Add your components here
    BreadCrumbComponent,
  },

};
</script>

<style scoped>
/* Add your styles here */
</style>
