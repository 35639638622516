/* eslint-disable no-param-reassign */
import { getSelectedDate, getGlobalEvents, setGlobalEvents } from './model.js';
import { subscribe, BUS_EVENT_TYPES, publish } from './eventBus.js';

/**
 * Reset time range component
 */
function resetTimeRangeValues() {
  document.querySelector('#timeFilter').hidden = true;
  document.getElementById('startTime').value = '00:00';
  document.getElementById('endTime').value = '23:59';
}

subscribe(BUS_EVENT_TYPES.loading, resetTimeRangeValues);

/**
 *
 * @param {string} value - element value
 * @returns {boolean} - is valid time
 */
function validateTime(value) {
  const timeRegex = /^(?:[0-9]|0[0-9]|1\d|2[0-3]):[0-5]\d$/;
  return timeRegex.test(value);
}

/**
 *
 */
function timeRangeUpdated() {
  const startTime = document.getElementById('startTime').value;
  const endTime = document.getElementById('endTime').value;
  if (!validateTime(startTime)) {
    document.getElementById('startTime').value = '00:00';
    return;
  }

  if (!validateTime(endTime)) {
    document.getElementById('endTime').value = '23:59';
    return;
  }
  // create date object with date from getSelectedDate and time from startTime
  const startDateTime = new Date(`${getSelectedDate().toDateString()} ${startTime}`);
  const endDateTime = new Date(`${getSelectedDate().toDateString()} ${endTime}`);

  const globalEvents = getGlobalEvents();
  globalEvents.forEach((avlEvent) => {
    const eventTime = new Date(avlEvent.eventTime);
    avlEvent.visible = eventTime >= startDateTime && eventTime <= endDateTime;
  });
  setGlobalEvents(globalEvents);
  publish(BUS_EVENT_TYPES.tableMapDataUpdated);
}

/**
 * when startTime or endTime is changed, call timeRangeUpdated
 */
function setupTimeRange() {
  document.getElementById('startTime').addEventListener('change', timeRangeUpdated);
  document.getElementById('endTime').addEventListener('change', timeRangeUpdated);
}

export { setupTimeRange, resetTimeRangeValues };
