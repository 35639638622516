import { BUS_EVENT_TYPES, publish } from './eventBus.js';

const Settings = {
  showHPOverTimeChart: 'showHPOverTimeChart',
};

/**
 * Function to save checkbox states to local storage
 */
function saveCheckboxStates() {
  const checkboxes = document.querySelectorAll('input[type="checkbox"]');
  const checkboxStates = {};

  checkboxes.forEach((checkbox) => {
    if (checkbox instanceof HTMLInputElement) checkboxStates[checkbox.id] = checkbox.checked;
  });

  localStorage.setItem('checkboxStates', JSON.stringify(checkboxStates));
}

/**
 *
 */
function loadSettingsStates() {
  const storedStates = localStorage.getItem('checkboxStates');

  if (storedStates) {
    const checkboxStates = JSON.parse(storedStates);

    // Use Object.keys to iterate through stored checkbox states
    Object.keys(checkboxStates).forEach((checkboxId) => {
      const checkbox = document.getElementById(checkboxId);

      if (checkbox && checkbox instanceof HTMLInputElement) {
        checkbox.checked = checkboxStates[checkboxId];
      }
    });
  }
}

/**
 *
 */
function setupSettingsEventListners() {
  const settingsModal = document.getElementById('settings-modal');

  const checkboxes = settingsModal.querySelectorAll('input[type="checkbox"]');
  checkboxes.forEach((checkbox) => {
    checkbox.addEventListener('change', saveCheckboxStates);
    checkbox.addEventListener('change', (eventContext) => {
      publish(BUS_EVENT_TYPES.settingsUpdated, {
        id: eventContext.target.id,
      });
    });
  });

  loadSettingsStates();
}

/**
 * Function to check if a setting is true or false
 * @param {string} settingName - name of the setting
 * @returns {boolean} - setting state
 */
function getSettingValue(settingName) {
  const storedStates = localStorage.getItem('checkboxStates');

  if (storedStates) {
    const checkboxStates = JSON.parse(storedStates);

    // eslint-disable-next-line no-prototype-builtins
    if (checkboxStates.hasOwnProperty(settingName)) {
      return checkboxStates[settingName];
    }
  }

  // Default value if the setting is not found or local storage is empty
  return false; // You can change this to true or any other default value if needed
}

export {
  Settings, loadSettingsStates, getSettingValue, setupSettingsEventListners,
};
