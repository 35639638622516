import renderEventSummary from './eventsSummary.js';
import addTable from './avlEventsTable.js';
import {
  renderGeoJson, features, clearMarkers,
} from './geo.js';
import { BUS_EVENT_TYPES, subscribe } from './eventBus.js';
import { isAdmin } from './userRole.js';

let globalEvents = [];
let selectedDate = new Date();

/**
 *
 * @param {*} eventContext - Event context
 */
function renderData(eventContext) {
  const { avlEvents } = eventContext.detail;
  const data = avlEvents;
  globalEvents = data;
  if (data.length > 0) {
    clearMarkers();
    const visibleEvents = data.filter((avlEvent) => avlEvent.visible);
    renderGeoJson(visibleEvents);
    renderEventSummary(visibleEvents);
    addTable(visibleEvents, features);
    if (isAdmin()) {
      document.getElementById('timeFilter').hidden = false;
    }
  } else {
    const table = document.getElementById('data');
    table.innerHTML = 'No events for the selected date';
  }
}

/**
 * @returns {Array} globalEvents
 */
function getGlobalEvents() {
  return globalEvents;
}

/**
 *
 * @param {*} events - events
 */
function setGlobalEvents(events) {
  globalEvents = events;
  renderData(globalEvents);
}

subscribe(BUS_EVENT_TYPES.submitBtnClicked, (eventContext) => {
  const dateArray = eventContext.detail.selectedDate.split('-');
  const year = parseInt(dateArray[0], 10);
  const month = parseInt(dateArray[1], 10) - 1; // Months are 0-indexed, so subtract 1
  const day = parseInt(dateArray[2], 10);

  selectedDate = new Date(year, month, day);
});

subscribe(BUS_EVENT_TYPES.dataLoaded, renderData);

/**
 *@returns {Date} selectedDate
 */
function getSelectedDate() {
  return selectedDate;
}

export {
  renderData, getGlobalEvents, getSelectedDate, setGlobalEvents,
};
