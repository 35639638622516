import { createApp } from 'vue';
import DeviceDetailsComponent from './components/DeviceDetailsComponent.vue';
import AppTemp from './AppTemp.vue';

import { setupSettingsEventListners } from './modules/settingsComponent.js';
import './modules/eventsTimelineChart.js';

import { setupTimeRange } from './modules/timeRange.js';

import './modules/auth.js';

import './modules/admin.js';

document.addEventListener('DOMContentLoaded', async () => {
  setupTimeRange();
  setupSettingsEventListners();

  const app = createApp(AppTemp);
  app.mount('#app');

  const deviceDetails = createApp(DeviceDetailsComponent);
  deviceDetails.mount('#device-details');
});
